import React, { ReactElement } from 'react';
import { styled } from 'linaria/react';

import { useHash, useQueryParam } from './hooks';
import AppBar from './components/AppBar';
import AppMain from './components/AppMain';
import dashboards from './dashboards';

const Root = styled.div`
  :global() {
    html, body, #app {
      margin:0;
      background: #192124;
      color: white;
      height: 100%;
    }
  }

  display: flex;
  flex-direction: column;
  height: 100%;
`;

function App(): ReactElement {
  const [page, setPage] = useHash();
  const [autoplay, setAutoplay] = useQueryParam('autoplay', false);

  const activeTab = Math.max(0, dashboards.findIndex((item) => page === item.name));

  const setActiveTab = (index): void => {
    const dashboard = dashboards[index];
    setPage(dashboard.name);
  };

  return (
    <Root>
      <AppBar
        dashboards={dashboards}
        autoplay={autoplay}
        onAutoplayChange={(): void => setAutoplay(!autoplay)}
        activeTab={activeTab}
        onTabChange={setActiveTab}
      />
      <AppMain
        autoplay={autoplay}
        dashboards={dashboards}
        onTabChange={setActiveTab}
        activeTab={activeTab}
      />
    </Root>
  );
}

export default App;

/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { styled } from 'linaria/react';

const SvgContainer = styled.div`
  position: relative;
  flex-grow: 1;

  svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

export default SvgContainer;

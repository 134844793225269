/* eslint-disable react/jsx-props-no-spreading */
import React, { FunctionComponent, Suspense } from 'react';
import { styled } from 'linaria/react';

import Loader from './Loader';
import Widget from './Widget';
import ErrorBoundary from './ErrorBoundary';

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
`;

const Panel = styled.div`
  flex-grow: 1;
  background: #1F2C34;
  padding: 1rem;
  border-radius: 10px;
  margin: 0.5rem;
  display: flex;

  @media only screen and (max-width: 768px) {
    margin: 0.25rem;
  }
`;

enum Direction {
  Row = 'row',
  Column = 'column',
}

const invertDirection = (direction: Direction): Direction => {
  if (direction === Direction.Row) {
    return Direction.Column;
  }
  return Direction.Row;
};


interface ContentProps {
  content: any | any[];
  direction?: Direction;
}

const Content: FunctionComponent<ContentProps> = ({ content, direction = Direction.Row }) => {
  if (Array.isArray(content)) {
    return (
      <Container style={{ flexDirection: direction }}>
        {content.map((item, index) => (
          <Content key={index} content={item} direction={invertDirection(direction)} />))}
      </Container>
    );
  }
  return (
    <Panel>
      <ErrorBoundary>
        <Suspense fallback={<Loader />}>
          <Widget content={content} />
        </Suspense>
      </ErrorBoundary>
    </Panel>
  );
};

export default Content;

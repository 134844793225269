import React, { FunctionComponent } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { virtualize, autoPlay } from 'react-swipeable-views-utils';

import { styled } from 'linaria/react';

import Content from './Content';

const VirtualizeSwipeableViews = autoPlay(virtualize(SwipeableViews));

const Root = styled.div`
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  overflow-y: scroll;

  .&::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    padding: 0.25rem;
  }

  :global() {
    .react-swipeable-view-container {
      height: 100%;
    }
  }
`;

interface DashboardProps {
  dashboards?: [any];
  onTabChange: (number) => void;
  activeTab: number;
  autoplay: boolean;
}

const AppMain: FunctionComponent<DashboardProps> = ({
  dashboards, onTabChange, activeTab, autoplay = false,
}) => {
  const slideRenderer: FunctionComponent<{key: any; index: number}> = ({ key, index }) => {
    const { content } = dashboards[index];
    return (
      <Root key={key}>
        <Container>
          <Content content={content} />
        </Container>
      </Root>
    );
  };
  return (
    <VirtualizeSwipeableViews
      autoplay={autoplay}
      interval={10000}
      style={{ height: '100%' }}
      index={activeTab}
      onChangeIndex={onTabChange}
      slideRenderer={slideRenderer}
      overscanSlideAfter={1}
      overscanSlideBefore={1}
      slideCount={dashboards.length}
    />
  );
};
export default AppMain;
